// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(function(i){return i[1]});
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ure0h9WU:not(:first-child){padding-top:max(.63rem,min(.94vw,1.13rem))}.InaBaMnU:not(:first-child){padding-top:max(2.25rem,min(3.33vw,4rem))}._1-LV3\\+6K{font-size:max(1.13rem,min(1.25vw,1.5rem));font-weight:600;word-wrap:break-word}.-zmrIYCx,._1-LV3\\+6K{line-height:1.14;margin-bottom:max(1.46rem,min(2.17vw,2.6rem))}.-zmrIYCx{font-size:max(.75rem,min(.73vw,.88rem));max-width:80ch;opacity:.6}.Pje5MPD4{display:flex;flex-wrap:wrap;gap:max(.28rem,min(.42vw,.5rem)) max(1.13rem,min(1.67vw,2rem))}.Pje5MPD4>*{flex-basis:calc(90%/var(--cols) - (var(--cols) - 1)*max(.28rem, min(.42vw, .5rem)));flex-grow:1}@media(max-width:667.98px){.Pje5MPD4>*{flex-basis:100%}}", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"xs": "667.98",
	"sm": "947.98",
	"md": "1023.98",
	"lg": "1229.98",
	"group": "ure0h9WU",
	"groupPadded": "InaBaMnU",
	"groupHeading": "_1-LV3+6K",
	"groupDescription": "-zmrIYCx",
	"groupFields": "Pje5MPD4"
};
module.exports = ___CSS_LOADER_EXPORT___;
