
import Vue from "vue"
import { isConditionsMet } from "@/utils/widget"
import { scrollToFirstError } from "@/utils/error"
import type { FieldsGroups, Field, ConditionsContext } from "@/types/api/fields"

type Model = Record<string, any>

export default Vue.extend({
  props: {
    value: { type: Object as VuePropType<Model>, required: true },
    name: { type: String, required: true },
    errors: {
      type: Object as VuePropType<Record<string, string>>,
      default: () => ({}),
    },
    context: { type: Object as VuePropType<ConditionsContext>, required: true },
    disabledFields: { type: Array as VuePropType<string[]>, default: () => [] },
  },

  data () {
    return {
      groups: [] as FieldsGroups,
      visibleFields: [] as string[],
      requiredFields: [] as string[],
      model: this.value,
      errorsModel: this.errors,
    }
  },

  async fetch () {
    await this.fetchGroups()
  },

  computed: {
    notEmptyGroups (): FieldsGroups {
      const { visibleFields } = this
      return this.groups.filter(g => g.fields.map(f => f.alias)
        .some(f => visibleFields.includes(f)))
    },
  },

  watch: {
    value: {
      handler (value) { this.model = value },
      deep: true,
    },
    model: {
      handler (model) {
        this.$emit("input", model)
        this.updateFields()
      },
      deep: true,
    },

    errors: {
      handler (errors) { this.errorsModel = errors },
      deep: true,
    },
    errorsModel: {
      handler (errorsModel) { this.$emit("update:errors", errorsModel) },
      deep: true,
    },
  },

  methods: {
    async fetchGroups () {
      const [res, err] = await this.$api.form.getFormData(this.name)
      if (err) return console.error(err.response.data)
      this.groups = res || []
      this.removeUnnecessaryFields()
      this.updateFields()
    },

    computeField (widget: Field["widget"]): Record<string, boolean> {
      const { model, context } = this
      let [isVisible, isRequired] = [false, false]

      const { visible } = widget.options
      if (typeof visible === "boolean") isVisible = visible
      else if (isConditionsMet(visible, model, context)) isVisible = true

      if (widget.type !== "translatableText") {
        const { required } = widget.options
        if (typeof required === "boolean") isRequired = required
        else if (isConditionsMet(required, model, context)) isRequired = true
      }

      return { isVisible, isRequired }
    },

    updateFields () {
      const visibleFields: string[] = []
      const requiredFields: string[] = []

      this.groups.forEach((g) => {
        g.fields.forEach((f) => {
          const { alias } = f
          const { isVisible, isRequired } = this.computeField(f.widget)
          if (isVisible) visibleFields.push(alias)
          if (isRequired) requiredFields.push(alias)

          if (f.widget.type === "translatableText") {
            const { children } = f.widget.options
            for (const k in f.widget.options.children) {
              const childAlias = `${alias}.${k}`
              const { isVisible, isRequired } = this.computeField(children[k])
              if (isVisible) visibleFields.push(childAlias)
              if (isRequired) requiredFields.push(childAlias)
            }
          }
        })
      })

      this.visibleFields = visibleFields
      this.requiredFields = requiredFields
    },

    removeUnnecessaryFields () {
      const formFields = this.groups.flatMap(g => g.fields).map(f => f.alias)
      const { isEdit } = this.context

      for (const k in this.model) {
        if (k === "oauth")
          if (isEdit) delete this.model[k]
          else continue

        if (k === "registrationToken") continue

        if (!formFields.includes(k) || this.model[k] === null)
          delete this.model[k]
      }
    },

    async onSubmit () {
      const { validationObserver } = this.$refs
      if (!validationObserver) return
      const isValid = await (validationObserver as any).validate()
      if (isValid) this.$emit("submit", this.model)
      else scrollToFirstError()
    },

    descVisible (group: FieldsGroups[number]): boolean {
      const { description } = group
      return !Array.isArray(description) && Boolean(description)
    },
  },
})
